<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Agregar producto</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Images-->
        <div class="flex -mx-2">
          <div class="w-1/5 p-2">
            <!-- Image 1 -->
            <template v-if="product.image1">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image1" alt="img" class="responsive">
              </div>
              <!-- Image upload Buttons -->
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image1=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="w-1/5 p-2">
            <!-- Image 2-->
            <template v-if="product.image2">

              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image2" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image2=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="w-1/5 p-2">
            <!-- Image 3 -->
            <template v-if="product.image3">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image3" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image3=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="w-1/5 p-2">
            <!-- Image 4 -->
            <template v-if="product.image4">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image4" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image4=null">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="w-1/5 p-2">
            <!-- Image 5 -->
            <template v-if="product.image5">
              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image5" alt="img" class="responsive">
              </div>
              <div class="modify-img flex justify-between mt-5">
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="product.image5=null">Eliminar</span>
              </div>
            </template>
          </div>
        </div>
        <div class="flex -mx-2">
          <!-- Upload image 1 -->
          <div class="upload-img mt-5 w-1/2 p-2" v-if="!product.image1">
            <input id="image1" type="file" class="hidden" ref="uploadImgInput1" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput1.click()">1 (1080x1620)</vs-button>
          </div>

          <!-- Upload image 2-->
          <div class="upload-img mt-5  w-1/2 p-2" v-if="!product.image2">
            <input id="image2" type="file" class="hidden" ref="uploadImgInput2" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput2.click()">2 (1080x1620)</vs-button>
          </div>

          <!-- Upload image 3-->
          <div class="upload-img mt-5  w-1/2 p-2" v-if="!product.image3">
            <input id="image3" type="file" class="hidden" ref="uploadImgInput3" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput3.click()">3 (1080x1620)</vs-button>
          </div>

          <!-- Upload image 4-->
          <div class="upload-img mt-5  w-1/2 p-2" v-if="!product.image4">
            <input id="image4" type="file" class="hidden" ref="uploadImgInput4" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput4.click()">4 (1080x1620)</vs-button>
          </div>

          <!-- Upload image 5-->
          <div class="upload-img mt-5  w-1/2 p-2" v-if="!product.image5">
            <input id="image5" type="file" class="hidden" ref="uploadImgInput5" @change="updateImage"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput5.click()">5 (1080x1620)</vs-button>
          </div>
        </div>
        <!--End-->

        <!-- Name -->
        <div class="mt-5">
          <vs-input @input="setSlug" @blur="product.name= trimString(product.name)" label="Nombre"
                    v-model="product.name"
                    class="w-full" name="name"
                    v-validate="'required|min:3|max:150'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('name')">{{ errors.first('name') }}</span>
        </div>

        <!-- Colors -->
        <div class="mt-5">
          <label class="vs-select--label" for="colors">Selecciona un color</label>
          <v-select v-validate="'required'" @input="setSlug" id="colors" label="name" :options="colors"
                    v-model="product.color"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" name="color"/>
          <span class="text-danger text-sm"
                v-show="errors.has('color')">{{ errors.first('color') }}</span>
        </div>
        <!--End-->

        <!-- Categories -->
        <div class="mt-5">
          <label class="vs-select--label" for="categories">Selecciona una categoria</label>
          <v-select v-validate="'required'" id="categories" label="name" :options="categories"
                    v-model="product.category"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" name="category"/>
          <span class="text-danger text-sm"
                v-show="errors.has('category')">{{ errors.first('category') }}</span>
        </div>
        <!--End-->

        <!-- SKU -->
        <div class="mt-5">
          <vs-input @blur="product.sku= trimString(product.sku)" label="SKU" v-model="product.sku"
                    class="w-full" name="sku"
                    v-validate="'required|min:3|max:50'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('sku')">{{ errors.first('sku') }}</span>
        </div>

        <!-- Slug -->
        <div class="mt-5">
          <vs-input :disabled="true" @blur="product.slug= trimString(product.slug)" label="Slug"
                    v-model="product.slug"
                    class="w-full" name="slug"
                    v-validate="'required|min:3'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('slug')">{{ errors.first('slug') }}</span>
        </div>

        <!---Prices--->
        <div class="flex -mx-2 mt-5">
          <!-- Normal price -->
          <div class="w-1/2 p-2">
            <vs-input type="number" label="Precio normal" v-model.number="product.normalPrice" class="w-full"
                      name="normalPrice"
                      v-validate="'required|min_value:1'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('normalPrice')">{{ errors.first('normalPrice') }}</span>
          </div>
          <div class="w-1/2 p-2">
            <!-- Price Discount -->
            <vs-input type="number" label="Precio con descuento" v-model.number="product.priceDiscount"
                      class="w-full"
                      name="priceDiscount"
                      v-validate="'required|min_value:1'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('priceDiscount')">{{ errors.first('priceDiscount') }}</span>
          </div>
        </div>
        <!--End-->

        <!-- Description -->
        <div class="mt-5">
          <vs-textarea rows="5" @blur="product.description= trimString(product.description)" label="Descripción"
                       v-model="product.description" class="w-full"
                       name="description"
                       v-validate="'required|min:5|max:1000'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('description')">{{ errors.first('description') }}</span>
        </div>

        <!-- Order -->
        <div class="mt-5">
          <vs-input type="number" label="Orden"
                    v-model.number="product.order"
                    class="w-full" name="order"
                    v-validate="'min_value:0'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('order')">{{ errors.first('order') }}</span>
        </div>
        <!--End-->

        <!-- Collections -->
        <div class="mt-5">
          <label class="vs-select--label" for="sections">Colección</label>
          <v-select multiple id="collections" label="name"
                    :options="['Men', 'Women', 'Unisex', 'Packs']"
                    v-model="product.collections"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" name="collections"/>
        </div>
        <!--End-->

        <!-- Related products -->
        <div class="mt-5">
          <label class="vs-select--label" for="relatedProducts">Selecciona productos relacionados</label>
          <v-select multiple id="relatedProducts" label="label" :options="listForRelatedProducts"
                    v-model="product.relatedProducts"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" name="relatedProducts"/>
        </div>
        <!--End-->

        <!--Upload size guide-->
        <div class="upload-img mt-5">
          <img class="mb-5" v-if="product.sizeGuide" :src="product.sizeGuide" alt="Guia de tallas">
          <input id="sizeGuide" type="file" class="hidden" ref="uploadSizeImage" @change="updateSizeGuide"
                 accept="image/*">
          <vs-button style="width: 100%" @click="$refs.uploadSizeImage.click()">
            Subir guia de tallas
          </vs-button>
        </div>

        <!-- Father -->
        <div v-if="product.isVariant === 'true'" class="mt-5">
          <label class="vs-select--label" for="father">Selecciona el producto principal</label>
          <v-select id="father" label="label" :options="productsAll" v-model="product.father"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" name="product"/>
        </div>
        <!--End-->

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import vSelect from 'vue-select'

const firebase = require('firebase')
// Required for side-effects
require('firebase/firestore')
let db = firebase.firestore()
export default {
  name: 'NewProduct',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    productsAll: {
      type: Array,
      required: true
    },
    listForRelatedProducts: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    highlightCategories: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [trimString],
  data () {
    return {
      product: {
        stock: 0,
        normalPrice: 0,
        priceDiscount: 0,
        isVariant: 'false'
      },
      sections: [
        {
          value: 'Best sellers',
          label: 'Best sellers'
        },
        {
          value: 'Lo nuevo',
          label: 'Lo nuevo'
        },
        {
          value: 'Outlet',
          label: 'Outlet'
        }
      ],
      newImage1: null,
      newImage2: null,
      newImage3: null,
      newImage4: null,
      newImage5: null,
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      deleteImageFlag: false
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.initValues()
        this.$validator.reset()
      }
    },
    product: {
      handler () {
        if (this.product.image1 === null) {
          this.newImage1 = null
        }
        if (this.product.image2 === null) {
          this.newImage2 = null
        }
        if (this.product.image3 === null) {
          this.newImage3 = null
        }
        if (this.product.image4 === null) {
          this.newImage4 = null
        }
        if (this.product.image5 === null) {
          this.newImage5 = null
        }
      },
      deep: true
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    submitData () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.progress = true
          // Images
          if (this.newImage1 != null) {
            this.product.image1 = await this.submitImages(this.newImage1)
          }
          if (this.newImage2 != null) {
            this.product.image2 = await this.submitImages(this.newImage2)
          }
          if (this.newImage3 != null) {
            this.product.image3 = await this.submitImages(this.newImage3)
          }
          if (this.newImage4 != null) {
            this.product.image4 = await this.submitImages(this.newImage4)
          }
          if (this.newImage5 != null) {
            this.product.image5 = await this.submitImages(this.newImage5)
          }
          // End
          let obj = {
            ...this.product,
            uid: firebase.auth().currentUser.uid,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            stock: 0
          }
          db.collection('products').add({
            ...obj
          }).then((doc) => {
            obj.id = doc.id
            this.$store.dispatch('products/addProduct', { product: obj })
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Producto',
              text: 'Producto creado correctamente.'
            })
            this.$emit('addToRelated', obj)
            // this.$emit('closeSidebar')
          }).catch(() => {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          }).finally(() => {
            this.progress = false
          })
        }
      })
    },
    submitImages (image) {
      return new Promise((resolve, reject) => {
        let imageName = Math.random().toString(36).substr(1, 20)
        let fileExtension = '.' + image.name.split('.').pop()
        let imageNameFinal = imageName + fileExtension

        let storageRef = firebase.storage().ref(`products/${imageNameFinal}`)
        storageRef.put(image)
            .then((snapshot) => {
              snapshot.ref.getDownloadURL().then((url) => {
                resolve(url)
              })
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    updateImage (input) {
      this.imageError = ''
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          if (input.srcElement.id === 'image1') {
            this.$set(this.product, 'image1', e.target.result)
            this.newImage1 = input.target.files[0]
            if (this.newImage1.size > 5000000) {
              this.imageError = 'La imágen no puede pesar más de 1mb'
              this.product.image1 = null
              this.newImage1 = null
            }
          } else if (input.srcElement.id === 'image2') {
            this.$set(this.product, 'image2', e.target.result)
            this.newImage2 = input.target.files[0]
            if (this.newImage2.size > 5000000) {
              this.imageError = 'La imágen no puede pesar más de 1mb'
              this.product.image2 = null
              this.newImage2 = null
            }
          } else if (input.srcElement.id === 'image3') {
            this.$set(this.product, 'image3', e.target.result)
            this.newImage3 = input.target.files[0]
            if (this.newImage3.size > 5000000) {
              this.imageError = 'La imágen no puede pesar más de 1mb'
              this.product.image3 = null
              this.newImage3 = null
            }
          } else if (input.srcElement.id === 'image4') {
            this.$set(this.product, 'image4', e.target.result)
            this.newImage4 = input.target.files[0]
            if (this.newImage4.size > 5000000) {
              this.imageError = 'La imágen no puede pesar más de 1mb'
              this.product.image4 = null
              this.newImage4 = null
            }
          } else if (input.srcElement.id === 'image5') {
            this.$set(this.product, 'image5', e.target.result)
            this.product.image5 = e.target.result
            this.newImage5 = input.target.files[0]
            if (this.newImage5.size > 5000000) {
              this.imageError = 'La imágen no puede pesar más de 1mb'
              this.product.image5 = null
              this.newImage5 = null
            }
          }
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    /**
     * Upload size guide
     * @param input
     * @returns {Promise<void>}
     */
    async updateSizeGuide (input) {
      this.progress = true
      const sizeGuide = await this.submitImages(input.target.files[0])
      this.$set(this.product, 'sizeGuide', sizeGuide)
      this.progress = false
      this.$refs.uploadSizeImage.value = ''
    },
    deleteImage (image) {
      if (image === 'image1') {
        this.product.image1 = null
        this.newImage1 = null
      } else if (image === 'image2') {
        this.product.image2 = null
        this.newImage2 = null
      } else if (image === 'image3') {
        this.product.image3 = null
        this.newImage3 = null
      } else if (image === 'image4') {
        this.product.image4 = null
        this.newImage4 = null
      } else if (image === 'image5') {
        this.product.image5 = null
        this.newImage5 = null
      }
    },
    setSlug () {
      const color = this.product.color ? this.product.color.name : ''
      this.$set(this.product, 'slug', this.getSlug(this.product.name + '-' + color))
    },
    getSlug (name) {
      let text = (name).trim().split(' ').join('-').toLowerCase().normalize('NFD')
          .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')
          .normalize()
      return text
    },
    initValues () {
      this.product = {
        stock: 0,
        normalPrice: 0,
        priceDiscount: 0,
        isVariant: 'false',
        image1: null,
        image2: null,
        image3: null,
        image4: null,
        image5: null
      }
      this.newImage1 = null
      this.newImage2 = null
      this.newImage3 = null
      this.newImage4 = null
      this.newImage5 = null
      this.progress = false
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 950px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
