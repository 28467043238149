<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Tallas de {{ product.name }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <div class="flex -mx-2">
          <!-- Size -->
          <div class="w-1/2 p-2">
            <vs-input label="Talla"
                      v-model="newSize.size"
                      class="w-full"/>
          </div>
          <!-- Stock -->
          <div class="w-1/2 p-2">
            <vs-input type="number" label="Stock"
                      v-model.number="newSize.stock"
                      class="w-full"/>
          </div>

          <!-- Order -->
          <div class="w-1/2 p-2">
            <vs-input type="number" label="Orden"
                      v-model.number="newSize.order"
                      class="w-full"/>
          </div>
        </div>

        <vs-button class="mr-6 mt-2" @click="addSize" :disabled="!isFormValid || progress">
          Agregar
        </vs-button>
      </div>

      <!--Products sizes-->
      <div class="pl-6 pr-6" v-for="(s, index) in orderedSizes" :key="index">
        <div class="grid grid-cols-7 gap-3">
          <div class="col-span-6">
            <!--Sizes list-->
            <div class="flex -mx-2">
              <!-- Size -->
              <div class="w-1/2 p-2">
                <vs-input label="Talla"
                          v-model="s.size"
                          class="w-full"/>
              </div>
              <!-- Stock -->
              <div class="w-1/2 p-2">
                <vs-input min="0" type="number" label="Stock"
                          v-model.number="s.stock"
                          class="w-full"/>
              </div>

              <!-- Order -->
              <div class="w-1/2 p-2">
                <vs-input min="0" type="number" label="Orden"
                          v-model.number="s.order"
                          class="w-full"/>
              </div>
            </div>
          </div>

          <div class="col-span-1 flex justify-center items-end pb-5">
            <feather-icon style="cursor:pointer" icon="TrashIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="deleteSize(s)"/>
          </div>
        </div>
      </div>

    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cerrar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'

let _ = require('lodash')

const firebase = require('firebase')
// Required for side-effects
require('firebase/firestore')
let db = firebase.firestore()
export default {
  name: 'Sizes',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [
    trimString
  ],
  data () {
    return {
      product: {},
      sizes: [],
      newSize: {},
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      changes: false,
      progress: false,
    }
  },
  watch: {
    async isSidebarActive (val) {
      if (!val) return
      await this.$validator.reset()
      this.product = _.cloneDeep(this.data)
      this.newSize = {}
      this.sizes = []
      await this.getSizes()
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    orderedSizes () {
      return this.sortSize(this.sizes)
    }
  },
  methods: {
    async submitData () {
      try {
        this.progress = true
        const batch = firebase.firestore().batch()
        this.sizes.forEach((s) => {
          const docRef = firebase.firestore().collection('products').doc(this.product.id).collection('sizes').doc(s.id)
          batch.update(docRef, {
            ...s,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            uid: firebase.auth().currentUser.uid
          })
        })
        await batch.commit()
        // Update stock un table
        this.product.stock = this.sizes.reduce((a, c) => {
          return a + c.stock
        }, 0)

        // Update product stock
        await firebase.firestore().collection('products').doc(this.product.id).update({
          stock: this.product.stock
        })

        await this.$store.dispatch('products/updateProduct', { product: this.product })
        this.$vs.notify({
          color: 'success',
          title: 'Producto',
          text: 'Tallas actualizadas.'
        })
        this.$emit('closeSidebar')
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    initValues () {
      this.product = {}
      this.newSize = {}
    },
    addSize () {
      try {
        if (this.validateNoRepeat() && this.newSize.stock >= 0 && this.newSize.size) {
          this.progress = true
          const obj = {
            ...this.newSize,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            uid: firebase.auth().currentUser.uid
          }
          db.collection('products').doc(this.product.id).collection('sizes').add({
            ...obj
          }).then((doc) => {
            obj.id = doc.id
            this.sizes.push({
              ...obj
            })
            this.product.stock = this.sizes.reduce((a, c) => {
              return a + c.stock
            }, 0)
            this.$store.dispatch('products/updateProduct', { product: this.product })
            this.newSize = {}
            this.$validator.reset()
            this.$vs.notify({
              color: 'success',
              title: 'Producto',
              text: 'Talla agregada.'
            })
            this.progress = false
          })
        } else {
          this.$vs.notify({
            color: 'warning',
            title: 'Producto',
            text: 'Revisa la información de la talla a ingresar'
          })
        }
      } catch (e) {
        this.progress = false
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    async deleteSize (size) {
      try {
        this.progress = true
        const sizeIndex = this.sizes.findIndex((s) => s.id === size.id)
        if (sizeIndex !== -1) {
          await firebase.firestore().collection('products').doc(this.product.id).collection('sizes').doc(size.id).delete()
          this.sizes.splice(sizeIndex, 1)
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    async getSizes () {
      const querySnapshot = await db.collection('products').doc(this.product.id).collection('sizes').orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let size = {
          id: doc.id,
          ...doc.data()
        }
        if (!size.order) {
          size.order = 100
        }
        this.sizes.push(size)
      })
    },
    sortSize (sizes) {
      return sizes.sort((a, b) => a.order - b.order)
    },
    changesAlert () {
      this.changes = true
    },
    validateNoRepeat () {
      let flag = 0
      this.sizes.forEach((f) => {
        if (f.size === this.newSize.size) {
          flag = 1
        }
      })
      return flag === 0
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 700px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
